<template>
  <el-container class="home-container">
    <el-header>
      <div class="title-box">
        <span class="title">捷思联想检验智能管控系统</span>
      </div>
      <div>
        <span class="username">{{ sysUser.fullName }} 欢迎您</span>
        <el-button type="info" size="mini" @click="logout">退出</el-button>
      </div>
    </el-header>
    <el-container>
      <el-aside width="200px">
        <el-menu background-color="#333744" text-color="#fff" active-text-color="#409eff" unique-opened :router="true"
                 :default-active="activePath">
          <el-menu-item :index="item.path" v-for="item in menuData" :key="item.id" @click="saveNavState(item.path)">
            <template>
              <i class="el-icon-menu"></i>
              <span>{{ item.menuName }}</span>
            </template>
          </el-menu-item>
        </el-menu>
      </el-aside>
      <el-main>
        <router-view/>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      sysUser: {
        fullName: ''
      },
      menuData: [
        {id: '1', menuName: '首页', path: '/admin/welcome'},
        {id: '2', menuName: '用户管理', path: '/admin/user/userList'},
        {id: '3', menuName: '角色管理', path: '/admin/role/roleList'},
        {id: '5', menuName: '系统参数管理', path: '/admin/config/list'},
        {id: '6', menuName: '接口调用记录查询', path: '/admin/log/interface'},
        {id: '7', menuName: '系统日志查询', path: '/admin/log/system'},
        {id: '8', menuName: '登录日志查询', path: '/admin/log/login'}
      ],
      activePath: ''
    };
  },
  methods: {
    logout() {
      this.$confirm('确定退出吗？', '提示', {
        cancelButtonText: '取消',
        confirmButtonText: '确定',
        type: 'warning'
      }).then(() => {
        let token = localStorage.getItem('token');
        if (token) {
          this.$axios.post('invalidToken', {token: token});
        }
        localStorage.removeItem('token');
        this.$router.push('/login')
      })
    },
    saveNavState(path) {
      localStorage.setItem('activePath', path);
      this.activePath = path;
    }
  },
  async created() {
    let {data: res} = await this.$axios.post('/user/getSysUser');
    if (res.code !== 0) {
      this.$message.error(res.message);
      return;
    }
    this.sysUser = res.data;

    this.activePath = localStorage.getItem('activePath')
  }
}
</script>

<style scoped>
.home-container {
  height: 100%;
}

.el-header {
  background-color: #373d41;
  color: #fff;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.el-header .username {
  font-size: 14px;
  padding-right: 10px;
}

.el-aside {
  background-color: #333744;
}

.el-main {
  background-color: #eaedf1;
}
</style>